<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card :title="$t('Checkk')">
          <label>{{ $t('search') }}</label>
          <b-row>
            <b-col
              cols="4"
            >
              <label>{{ $t('Checkk No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter1 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter1"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('Chassis No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter2 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter2"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('In all') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter3 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter3"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <br>
          <v-select
            v-model="data.checkk"
            :options="Checkks"
            :reduce="Checkks => Checkks.id"
            label="No"
          >
            <template #option="{ No , chassis_number }">
              <span> {{ No }} - {{ chassis_number }} </span>
            </template>
          </v-select>
          <small
            v-if="errors.checkk"
            class="text-danger"
          >{{ errors.checkk[0] }}</small>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="data.checkk"
          >
            <b-col
              v-for="el in ['no','No','chassis_number', 'chassis_number_type','owner_type1', 'created_year', 'first_registeraion',
                            'cabin_number','cabin_number_type','motor_number_type','motor_reserve_number','motor_reserve_number_type',
                            'motor_number','cabin_reserve_number_type','cabin_reserve_number','expiration_date','toll',
                            'color','fuel_type','car_type','motor_model','motor_manufacturer','wheel1','combustion1',
                            'light1','brake1','block_reason','block1','notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="SelectedCheckk(data.checkk)[el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <cleave
                  v-model="data.other_fee"
                  class="form-control mt-2"
                  :raw="false"
                  :options="{
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralDecimalScale: 0,
                  }"
                  :placeholder="$t('other_fee')"
                  :state="errors.other_fee ?false:null"
                  :class="errors.other_fee ?'is-invalid':null"
                />
                <small
                  v-if="errors.other_fee"
                  class="text-danger"
                >{{ errors.other_fee[0] }}</small>
                <label>{{ $t('other_fee') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            v-if="haveAccess(['Set Company'])"
            cols="12"
          >
            <b-card :title="$t('Company')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.company"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company"
                class="text-danger"
              >{{ errors.company[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            v-if="haveAccess(['Company Other'])"
            cols="12"
          >
            <b-card :title="$t('Company Fee Belong To')">
              <label>{{ $t('please select first ...') }}</label>
              <v-select
                v-model="data.company_from"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_from"
                class="text-danger"
              >{{ errors.company_from[0] }}</small>
              <label>{{ $t('please select second...') }}</label>
              <v-select
                v-model="data.company_to"
                :options="Companies"
                :reduce="Companies => Companies.id"
                label="name"
              />
              <small
                v-if="errors.company_to"
                class="text-danger"
              >{{ errors.company_to[0] }}</small>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="9"
      >
        <b-row>

          <b-col
            v-if="data.renewData === 'sale'"
            cols="12"
          >
            <b-card :title="$t('Owwner')">
              <label>{{ $t('please select...') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter"
                />
              </b-input-group>
              <v-select
                v-model="data.owner"
                :options="Owwners"
                :reduce="Owwners => Owwners.id"
                label="id"
              >
                <template #option="{ id , applicant , owner }">
                  <span> {{ id }} - {{ applicant }} - {{ owner }} </span>
                </template>
              </v-select>
              <small
                v-if="errors.owner"
                class="text-danger"
              >{{ errors.owner[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('old owwner applicant')">
              <b-row
                v-if="data.checkk"
              >
                <b-col
                  v-for="el in ['id', 'owner', 'applicant','date_time', 'phone', 'identify']"
                  :key="el"
                  cols="2"
                >
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="data1.owwner_data[el]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                البيانات غير متاحة
              </div>
            </b-card>
            <b-card :title="$t('new owwner applicant')">
              <b-row
                v-if="data.owner"
              >
                <b-col
                  v-for="el in ['id', 'owner', 'applicant','date_time', 'phone', 'identify']"
                  :key="el"
                  cols="2"
                >
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="SelectedOwner(data.owner)[el]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                البيانات غير متاحة
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-primary"
                    @click="print"
                  >
                    {{ $t('Print') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>

              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('invoice')">
              {{ $t('pay_fee') }} : {{ this.data.pay_fee }}$<br>
              {{ $t('other_fee') }} : {{ this.data.other_fee }}$<br>
              {{ $t('Total') }} : {{ this.data.pay_fee }} + {{ this.data.other_fee }} ={{ parseFloat(this.data.pay_fee) + parseFloat(this.data.other_fee) }}$<br>
              {{ $t('Total') }} : {{ Total }}<span>&#8378;</span><br>
            </b-card>
          </b-col>
        </b-row>
        <b-card
          id="printMe"
          style="visibility: hidden;"
        >
          <div style="width: 100%!important; height: 100%; background-color: white; position: relative; top: 0;right: 0;left: 0;bottom: 0;">
            <img
              src="@/assets/images/logo/logo.jpg"
              style="width: 80px;
            height: 86px;
position: fixed;top:0px;left: 0px;"
            >
            <h3 style="text-align: right; right: 78%!important; color: black;position: fixed;top:80px; ">
              {{ currentDateTime() }} :&nbsp; التاريخ
            </h3>
            <h3
              v-if="this.data1"
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:130px; "
            >
              {{ this.data1.No }} : رقم  المركبة
            </h3>

            <h2
              style="position: fixed;
                                top: 0px;
                                right: 0px;
                                text-align: right;
                               color: black; font-weight: bold;
                                font-family: 'Abdoullah Ashgar EL-kharef';"
            >المؤسسة العامة للنقل<br>مديرية النقل في  {{ user.company_data.name }}
            </h2>
            <h1
              style="position: fixed;
                                top: 0px;
                                right: 30%;
                                left: 30%;
                                width: 40%;
                                text-align: center;
                               color: black; font-weight: bold;
                                font-family: 'Abdoullah Ashgar EL-kharef';"
            >صك بيع مركبة قطعي<br>
            </h1>
            <h3 style="text-align: right; right: 0%!important;color: black;position: fixed;top:160px; ">
              مواصفات المركبة
              <hr style="margin-top: 0px!important;">
            </h3>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:190px; width: 33%"
            >
              &nbsp;:   الصانع
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:190px; width: 33%"
            >
              {{ this.data1.carr_data.manufacturer }}
            </h4>
            <h4
              style="text-align: right; right: 33%!important;color: black;position: fixed;top:190px; width: 33%"
            >

              &nbsp; :   الطراز
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 33%!important;color: black;position: fixed;top:190px; width: 33%"
            >
              {{ this.data1.carr_data.model }}
            </h4>
            <h4
              style="text-align: right; right: 66%!important;color: black;position: fixed;top:190px; width: 33%"
            >

              &nbsp;  : النوع

            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 66%!important;color: black;position: fixed;top:190px; width: 33%"
            >
              {{ $t(this.data1.carr_data.car_type) }} / {{ this.data1.owner_type1 }}
            </h4>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              &nbsp;:   سنة الصنع
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              {{ this.data1.created_year }}
            </h4>
            <h4
              style="text-align: right; right: 25%!important;color: black;position: fixed;top:220px; width: 25%"
            >

              &nbsp; :   سنة التسجيل الأولى
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 30%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              {{ this.data1.first_registeraion }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:220px; width: 25%"
            >

              &nbsp;  : اللون
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 50%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              {{ $t(this.data1.color) }}
            </h4>
            <h4
              style="text-align: right; right: 75%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              &nbsp;   : عدد المقاعد
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 75%!important;color: black;position: fixed;top:220px; width: 25%"
            >
              {{ $t(this.data1.carr_data.passenger) }}
            </h4>

            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
            >
              &nbsp; :   رقم الهيكل
            </h4>
            <h3
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:250px; width: 50%"
            >
              {{ this.data1.chassis_number }}
            </h3>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:250px; width: 50%"
            >
              &nbsp; :    رقم المحرك
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:250px; width: 40%"
            >
              {{ this.data1.motor_number }}
            </h4>
            <h3
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:285px; width: 50%"
            >
              &nbsp;    الفريق الأول
            </h3>
            <h3
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:285px; width: 50%"
            >
              &nbsp;الفريق الثاني
            </h3>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:320px; width: 50%"
            >
              &nbsp; :   الاسم
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:320px; width: 50%"
            >
              {{ this.data1.owwner_data.owner }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:320px; width: 50%"
            >
              &nbsp; :     الاسم
            </h4>
            <h4
              v-if="this.data.owner"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:320px; width: 50%"
            >
              {{ this.SelectedOwner(data.owner).owner }}
            </h4>

            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:345px; width: 50%"
            >
              &nbsp; :    الرقم الوطني
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:345px; width: 50%"
            >
              {{ this.data1.owwner_data.identify }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:345px; width: 50%"
            >
              &nbsp; :     الرقم الوطني
            </h4>
            <h4
              v-if="this.data.owner"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:345px; width: 50%"
            >
              {{ this.SelectedOwner(data.owner).identify }}
            </h4>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:370px; width: 50%"
            >
              &nbsp; :   محل ورقم القيد
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:370px; width: 50%"
            >
              {{ this.data1.owwner_data.placeNo }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:370px; width: 50%"
            >
              &nbsp; :     محل ورقم القيد
            </h4>
            <h4
              v-if="this.data.owner"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:370px; width: 50%"
            >
              {{ this.SelectedOwner(data.owner).placeNo }}
            </h4>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:395px; width: 50%"
            >
              &nbsp; :    هاتف
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 0%!important;color: black;position: fixed;top:395px; width: 50%"
            >
              {{ this.data1.owwner_data.phone }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:395px; width: 50%"
            >
              &nbsp; :     هاتف
            </h4>
            <h4
              v-if="this.data.owner"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:395px; width: 50%"
            >
              {{ this.SelectedOwner(data.owner).phone }}
            </h4>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:420px; width: 50%"
            >
              &nbsp; :   العنوان
            </h4>
            <h4
              v-if="this.data1"
              style="text-align: center; right: 10%!important;color: black;position: fixed;top:420px; width: 40%!important; "
            >
              {{ this.data1.owwner_data.currentAdres }}
            </h4>
            <h4
              style="text-align: right; right: 50%!important;color: black;position: fixed;top:420px; width: 50%"
            >
              &nbsp; :     العنوان
            </h4>

            <h4
              v-if="this.data.owner"
              style="text-align: center; right: 60%!important;color: black;position: fixed;top:420px; width: 40%!important;"
            >
              {{ this.SelectedOwner(data.owner).currentAdres }}
            </h4>
            <h4
              style="text-align: right; right: 0%!important;color: black;position: fixed;top:465px; width: 100%"
            >
              &nbsp; نحن الموقع أدناه ممثل مديرية النقل في  {{ user.company_data.name }}     نقر انه في الساعة  {{ currentHourTime() }}   من يوم   {{ currentDateTime() }}   قد حضر أمامنا في المكتب كلا من الفرقين المذكورين أعلاه وصرح كلا منهما بأن البيع تم مباشرة عن طريق وساطة أو وسيط كما يلي

            </h4>
            <h4
              style="text-align: right; right: 8%!important;color: black;position: fixed;top:515px; width: 2%"
            >
              -1

            </h4>
            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:515px; width: 100%"
            >
              &nbsp;    أقر أنا الفريق الأول أنني أملك المركبة المبينة أوصافها أعلاه بموجب بيان البيع الصادر عن مديرية النقل في  {{ user.company_data.name }} بأنني   <br>&nbsp; بعتها  بيعا قطعيا إلى الفريق الثاني على الوجه المبين أعلاه ببدل  متفق عليه قبضته من كاملا وأجيز له استلام المركبة وفق <br> الحصص المذكورة أعلاه

            </h4>
            <h4
              style="text-align: right; right: 8%!important;color: black;position: fixed;top:575px; width: 2%"
            >
              -2

            </h4>
            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:575px; width: 100%"
            >
              &nbsp;    أقر أنا الفريق الثاني أنني اشتريت المركبة المعينة بالفقرة الأولى من هذا الصك بالبدل المتفق عليه والذي سلمته الى الفريق<br>. الأول كلاملا . وطلبت تسجيل ذلك في سجل المركبات وإعطائي رخصة حسب الأصول

            </h4>
            <h4
              style="text-align: right; right: 8%!important;color: black;position: fixed;top:625px; width: 2%"
            >
              -3

            </h4>
            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:625px; width: 100%"
            >
              &nbsp;. على هذا الشكل تم الإيجاب والقبول والتسليم فيما بين الفريقين المتعاقدين ووقعا أدناه <br>واستوفى عنه رسوم التسجيل بموجب الايصال المالي رقم .......................   تاريخ  {{ currentDateTime() }}

            </h4>

            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:685px; width: 45%"
            >
              &nbsp; الفريق الأول
            </h4>

            <h4
              style="text-align: right; right: 55%!important;color: black;position: fixed;top:685px; width: 45%"
            >
              &nbsp; الفريق الثاني
            </h4>
            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:770px; width: 90%"
            >
              &nbsp;القيود المتممة

            </h4>
            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:815px; width: 90%"
            >
              &nbsp;مكتب التسجيل
            </h4>
            <h3
              v-if="this.data1"
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:835px; width: 90%"
            >
              محضر عقد رقم : ............ تاريخ :{{ currentDateTime() }} أنا الموقع رئيس تسجيل عقود السيارات أنه في<br>  الساعة {{ currentHourTime() }} قد تم تسجيل مضمون هذه العقود في السجل

            </h3>

            <h4
              style="text-align: right; right: 10%!important;color: black;position: fixed;top:900px; width: 50%"
            >
              &nbsp;رئيس مكتب العقود
            </h4>
            <h4
              style="text-align: right; right: 80%!important;color: black;position: fixed;top:900px; width: 20%"
            >
              &nbsp;المدير العام للنقل
            </h4>
          </div>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'نظامية' },
        { value: 'Assa', text: 'تجميع' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        renewData: 'sale',
        created_year: '',
        car: null,
        company: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        other_fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/GetAuth']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter1}`
    },
    Total() {
      let totalfee = 0
      if (this.data.fee) totalfee = parseInt(parseFloat(this.data.fee) + (parseFloat(this.data.other_fee) * this.Tolls.dollar), 10)
      return totalfee
    },
    Owwners() {
      return this.$store.getters['owwner/GetElements']
    },
    Companies() {
      return this.$store.getters['company/GetElements']
    },
    Checkks() {
      return this.$store.getters['checkk/GetElements']
    },
    Tolls() { return this.$store.getters['toll/GetElements'] },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.owwner': function (val) {
      if (!val) {
        this.clearData()
      }
    },
    'data.other_fee': function (val) {
      if (!val) {
        this.data.other_fee = 0
      }
    },
    'data.company': function (val) {
      if (!val) {
        this.clearData()
      }
    },
    'data.checkk': function (val) {
      if (val) {
        this.setData(val)
        this.data.fee = 0
        if (this.data.fee < 1 && this.data.created_year > 1900) {
          if (this.data.created_year < 2006) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourismOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourismOneBuy)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismTwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourismTwoBuy)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourismThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourismThreeBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transportOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transportOneBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportTwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transportTwoBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transportThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transportThreeBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialOneBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialTwoBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialThreeBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.busOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.busOneBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.busTwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.busTwoBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.busThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.busThreeBuy)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.govermentOneBuy)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.motorOneBuy)
            }
          } else if (this.data.created_year > 2005) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) {
              this.data.fee = (parseFloat(this.Tolls.tourism2OneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourism2OneBuy)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2TwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourism2TwoBuy)
            } else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) {
              this.data.fee = (parseFloat(this.Tolls.tourism2ThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.tourism2ThreeBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.transport2OneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transport2OneBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2TwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transport2TwoBuy)
            } else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'transport') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.transport2ThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.transport2ThreeBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight <= 3000) {
              this.data.fee = (parseFloat(this.Tolls.spicialOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialOneBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight < 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialTwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialTwoBuy)
            } else if ((this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work' || this.data.carr.car_type === 'spicial') && this.data.carr.wight >= 11000) {
              this.data.fee = (parseFloat(this.Tolls.spicialThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.spicialThreeBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 14) {
              this.data.fee = (parseFloat(this.Tolls.bus2OneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.bus2OneBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger <= 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2TwoBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.bus2TwoBuy)
            } else if ((this.data.carr.car_type === 'transport_ride' || this.data.carr.car_type === 'microbus' || this.data.carr.car_type === 'bus') && this.data.carr.passenger > 30) {
              this.data.fee = (parseFloat(this.Tolls.bus2ThreeBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.bus2ThreeBuy)
            } else if (this.data.carr.car_type === 'goverment') {
              this.data.fee = (parseFloat(this.Tolls.govermentOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.govermentOneBuy)
            } else if (this.data.carr.car_type === 'motor') {
              this.data.fee = (parseFloat(this.Tolls.motorOneBuy)) * parseFloat(this.Tolls.dollar)
              this.data.pay_fee = parseFloat(this.Tolls.motorOneBuy)
            }
          }
        }
        this.data.fee = parseFloat(this.data.fee)
      } else {
        this.clearData()
      }
    },
    // eslint-disable-next-line func-names
  },
  created() {
    this.$store.dispatch('checkk/GetElements')
    this.$store.dispatch('company/GetElements')
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('toll/GetPluckedElements')
  },
  methods: {
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const dateTime = `${date}`

      return dateTime
    },
    currentHourTime() {
      const current = new Date()
      const date = `${current.getHours()}:${current.getMinutes()}`
      const dateTime = `${date}`

      return dateTime
    },
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      // await this.$htmlToPaper('printMe', { options })
      if (this.data1 !== null && this.data1 !== undefined) {
        if (this.data.owner !== null && this.data.owner !== undefined) {
          await this.$htmlToPaper('printMe', { options, styles: ['/style.css'] })
        } else {
          alert('الرجاء اختيار  المالك الجديد ')
        }
      } else {
        alert('الرجاء اختيار الرخصة  ')
      }
      this.data.renewData = 'sale'
    },
    onEnter() {
      this.currentPage = 1
      this.getElementdata()
    },
    getElementdata() {
      this.$store.dispatch('owwner/GetAvailableElements', `page=1&search=${this.data.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.owwner = response.data.owwner[0].id
        this.data.owner = response.data.owwner[0].id
      })
    },
    onEnter1() {
      this.currentPage = 1
      this.getElementdata1()
      this.clearData()
      this.data.filter1 = ''
    },
    onEnter2() {
      this.currentPage = 1
      this.getElementdata2()
      this.clearData()
      this.data.filter2 = ''
    },
    onEnter3() {
      this.currentPage = 1
      this.getElementdata3()
      this.clearData()
      this.data.filter3 = ''
    },
    setData(val) {
      this.data.renewData = 'sale'
      this.data.brake = true
      this.data.light = true
      this.data.combustion = true
      this.data.wheel = true
      this.data1 = this.Checkks.find(el => el.id === val)
      this.data.chassis_number = this.data1.chassis_number
      this.data.color = this.data1.color
      this.data.expiration_date = this.data1.expiration_date
      this.data.brake = this.data1.brake
      this.data.light = this.data1.light
      this.data.combustion = this.data1.combustion
      this.data.wheel = this.data1.wheel
      this.data.company = this.data1.company_id
      this.data.owwner = this.data1.owwner
      this.data.created_year = this.data1.created_year
      this.data.carr = this.data1.carr_data
      this.data.owner_type = this.data1.owner_type
      if (this.data1.fuel_type === 'بنزين') { this.data.fuel_type = 'benzin' } else if (this.data1.fuel_type === 'مازوت') { this.data.fuel_type = 'mazot' } else if (this.data1.fuel_type === 'غاز') { this.data.fuel_type = 'gaz' }
    },
    getElementdata1() {
      this.$store.dispatch('checkk/GetElementsByCheckkNo', `page=1&search=${this.data.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata2() {
      this.$store.dispatch('checkk/GetElementsByChassisNo', `page=1&search=${this.data.filter2}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata3() {
      this.$store.dispatch('checkk/GetElementsByAll', `page=1&search=${this.data.filter3}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    clearData() {
      this.data = {
        fee: 0,
        total: 0,
        renewData: 'sale',
        other_fee: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        checkk: null,
        company: null,
        light: true,
        combustion: true,
        wheel: true,
        carr: null,
      }
    },
    SelectedCheckk(val) {
      return this.Checkks.find(el => el.id === val)
    },
    getData(val) {
      this.$store.dispatch('checkk/GetAvailableElements', val).then(() => {
        this.data = this.Element
      })
    },
    SelectedOwner(val) {
      return this.Owwners.find(el => el.id === val)
    },
    resetData() {
      this.data = {
        fee: 0,
        total: 0,
        renewData: 'sale',
        other_fee: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.data.fee = parseInt(this.Total, 10)
      this.errors = []
      this.$store.dispatch('renew/Create', this.data).then(() => {
        this.$store.dispatch('owwner/GetAvailableElements')
        this.clearData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
